@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
/* styles.css */

@font-face {
    font-family: 'Garamond-Light-Cond';
    src: url('assets/fonts/ITCGaramondStd-LtCond.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

.AG-font {
    font-family: 'Garamond-Light-Cond';
}